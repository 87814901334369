import MetaProductSchema from '@/modules/schemas/product/MetaProductSchema'

class MetaProduct {
  // Do not add any methods to this class
  constructor (data = MetaProductSchema.fields) {
    this.init({ ...MetaProductSchema.fields, ...data })
  }

  init (data) {
    this.url = data.url
    this.product_id = data.product_id
    this.campaign_id = data.campaign_id
    this.is_enabled = data.is_enabled
    this.attribute_set = data.attribute_set
    this.type = data.type
    this.name = data.name
    this.manufacturer = data.manufacturer
    this.manufacturer_url = data.manufacturer_url
    this.tags = data.tags
    this.image_src = data.image_src
    this.image_retina_src = data.image_retina_src
    this.reason_why_one = data.reason_why_one
    this.successor_url = data.successor_url
    this.show_catch_phrase = data.show_catch_phrase
    this.ratings_count = data.ratings_count
    this.ratings_average = data.ratings_average
    this.heart_ratings = data.heart_ratings
    this.catch_phrase = data.catch_phrase
    this.awards = data.awards
    this.last_purchase_date = data.last_purchase_date
    this.has_been_bought = data.has_been_bought
    this.rating = data.rating
    this.milesandmore = data.milesandmore
    this.recommendation = data.recommendation
    this.price = data.price
    this.wishlist_item_id = data.wishlist_item_id
    this.contents = data.contents
    this.image_hero_src = data.image_hero_src
    this.image_hero_retina_src = data.image_hero_retina_src
    this.vendor = data.vendor
    this.wine_alcohol_concentration = data.wine_alcohol_concentration
    this.distributor = data.distributor
    this.legal = data.legal
    this.legal_information_string = data.legal_information_string
    this.contains_sulfide = data.contains_sulfide
    this.delivery = data.delivery
    this.qty = data.qty
    this.ga_data = data.ga_data
    this.expert_reviews = data.expert_reviews
    this.wine_maker_products = data.wine_maker_products
    this.characteristics = data.characteristics
    this.bundle_items = data.bundle_items
    this.score_info = data.score_info ?? this.score_info
    this.campaign_banner = data.campaign_banner
    return this
  }
}

export default MetaProduct
