import Vue from 'vue'
import VueRouter from 'vue-router'
import { adminGuard, customerGuard } from '@/router/guards'
import TheOpcPage from '@/views/TheOpcPage.vue'
import TheCmsPage from '@/views/TheCmsPage.vue'
import TheErrorPage from '@/views/TheErrorPage.vue'
import TheCartPage from '@/views/TheCartPage.vue'
import TheOverviewPage from '@/views/TheOverviewPage.vue'
import ThePlayground from '@/views/ThePlayground.vue'
import TheCategoryPage from '@/views/TheCategoryPage.vue'
import TheSearchPage from '@/views/TheSearchPage.vue'
import TheWishListPage from '@/views/TheWishListPage.vue'
import TheHomePage from '@/views/TheHomePage.vue'
import TheProductDetailPage from '@/views/TheProductDetailPage.vue'
import TheManufacturerPage from '@/views/TheManufacturerPage.vue'
import TheCheckoutPage from '@/views/TheCheckoutPage.vue'

Vue.use(VueRouter)

const GTM = {
  cart: { modulePath: 'checkout/cart', pageCategory: '', applicationPageName: 'cart' },
  cms: { modulePath: 'cms/page/view', pageCategory: '', applicationPageName: 'cms' },
  category: { modulePath: 'catalog/category/list', pageCategory: '', applicationPageName: 'category' },
  search: { modulePath: 'search/index/index', pageCategory: '', applicationPageName: 'category' },
  pdp: { modulePath: 'catalog/product/view', pageCategory: '', pdpCategory: 'fe-app-pdp', applicationPageName: 'pdp' },
  opc: { modulePath: 'opc/index', pageCategory: '', applicationPageName: 'opc' },
  wishlist: { modulePath: 'wishlist/index', pageCategory: '', applicationPageName: 'wishlist' },
  home: { modulePath: 'cms/page/view', pageCategory: '', applicationPageName: 'cms', homepage: true }
}

const routes = [
  // static routes
  { path: '/', name: 'home', component: TheCmsPage },
  { path: '/error', name: 'error', component: TheErrorPage },
  { path: '/checkout/cart', name: 'cart', component: TheCartPage },
  { path: '/overview', name: 'overview', component: TheOverviewPage, beforeEnter: adminGuard },
  { path: '/playground', name: 'playground', component: ThePlayground, beforeEnter: adminGuard },
  { path: '/search', name: 'search', component: TheSearchPage },
  { path: '/merkliste', name: 'wishlist', component: TheWishListPage, beforeEnter: customerGuard  },
  { path: '/homepage-test', name: 'home_test', component: TheHomePage },
  // dynamic routes
  { path: '/(.*)-pdp-p:pId(\\d+)-c:cId(\\d+)(.*)', name: 'pdp', component: TheProductDetailPage },
  { path: '/(.*)-opc-p:pId(\\d+)-c:cId(\\d+)(.*)', name: 'opc', component: TheOpcPage },
  { path: '/(.*)-man-m:id(\\d+)(.*)', name: 'manufacturer', component: TheManufacturerPage },
  { path: '/(.*)-cat-c:id(\\d+)(.*)', name: 'category', component: TheCategoryPage },
  { path: '/(.*)-cms-p:id(\\d+)(.*)', name: 'cms', component: TheCmsPage },
  { path: '/checkout/:step', props:true, name: 'checkout', component: TheCheckoutPage, beforeEnter: adminGuard },
  { path: '/*', name: 'fallback', component: TheErrorPage }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async ({ path: to, name: toName },  { path: from, name: fromName }, next) => {
  if (fromName && from === to) {
    next()
    return
  }

  await _refreshData()
  void _setupDataLayer(toName)

  next()
})

router.afterEach(async ({ path: from }, { path: to }) => {
  const { WALKER_COMMANDS, elb, DomHelper } = await _getModules()

  setTimeout(async () => {
    elb(WALKER_COMMANDS.run)
  }, 0)

  if (from === to)  return

  DomHelper.scrollTo('#root')
})

const _refreshData = async () => {
  const { wishlist, cart, customer, lazyStore } = await _getModules()
  await customer.establishSession()
  await lazyStore.setForceLoad({ value: false })
  void cart.refreshCart()
  void wishlist.refreshWishlistItems()
  void wishlist.refreshWishlistSoldOutItems()
}

const _setupDataLayer = async (module) => {
  const { TagManager } = await _getModules()
  TagManager.pageUpdate(module, GTM[module])
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { useCustomerStore },
      { useWishlistStore },
      { useLazyStore },
      { useCartStore },
      { default: TagManager },
      { elb },
      { GLOBAL_STORE },
      { WALKER_COMMANDS },
      { default: DomHelper }
    ] = await Promise.all([
      await import('@/stores/customer'),
      await import('@/stores/wishlist'),
      await import('@/stores/lazyStore'),
      await import('@/stores/cart'),
      await import('@/services/analytics/TagManager'),
      await import('@elbwalker/walker.js'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/helpers/ElbwalkerHelper'),
      await import('@/services/helpers/DomHelper')
    ])
    cache = {
      customer: useCustomerStore(),
      wishlist: useWishlistStore(),
      lazyStore: useLazyStore(),
      cart: useCartStore(),
      TagManager,
      elb,
      GLOBAL_STORE,
      WALKER_COMMANDS,
      DomHelper
    }
    return cache
  }
})()

export default router
