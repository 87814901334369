import Vue from 'vue'
import VueI18n from 'vue-i18n'
import DEFAULT_TRANSLATIONS from '@/assets/translations/de-DE.json'

const DEFAULT_LOCALE = 'de-DE'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages: DEFAULT_TRANSLATIONS
})

