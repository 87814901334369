import { elb, Walkerjs } from '@elbwalker/walker.js'
import destinationGoogleGA4 from '@elbwalker/destination-web-google-ga4'
import { WALKER_COMMANDS } from '@/services/helpers/ElbwalkerHelper'
import ApiController from '@/services/ApiController'

const ELBWALKER_TOGGLE = 'log_elbwalker'

// Configuration

const urlParams = new URLSearchParams(window.location.search)
const partnerId = urlParams.get('partner_id')

window.walkerjs = Walkerjs({
  session: {
    storage: true,
    length: 30,
    data: {
      partner_id: partnerId
    }
  }
})
window.elb = elb

const ga4Config = {
  consent: {
    marketing: true
  },
  custom: {
    measurementId: 'G-WF8BMGZN9S',
    include: ['all'],
    snakeCase: true,
  },
  // loadScript: true
}

// Add destinations

elb(WALKER_COMMANDS.destination, { push: ApiController.sendEventToDataMining })

if (process.env.VUE_APP_TRACKING_ENABLED === 'ON' && location.hostname === 'www.vicampo.de') {
  elb(WALKER_COMMANDS.destination, destinationGoogleGA4, ga4Config)
}

if (localStorage.getItem(ELBWALKER_TOGGLE)) {
  elb(WALKER_COMMANDS.destination, { push: console.log })
}
