import './webVitals'

const load = () => import(/* webpackChunkName: "polyfills/basic" */'./load')

// Rule of thumb: No loop, no overhead
// Just add your polyfill to the condition and that's it
if (
  !('HTMLPictureElement' in window &&
    'URL' in window &&
    'fetch' in window &&
    'forEach' in NodeList.prototype &&
    'includes' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'entries' in Object &&
    'keys' in Object)
) {
  load()
}
