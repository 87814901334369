
const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { useCustomerStore }
    ] = await Promise.all([
      await import('@/stores/customer')
    ])
    cache = { customer: useCustomerStore() }
    return cache
  }
})()

const customerGuard = async (_t, _f, next) => {
  const { customer } = await _getModules()

  await customer.waitUntilPending()

  if (!customer.customerSoftLoggedIn) {
    location.replace('/login')
    return
  }
  next()
}

const adminGuard = async (_t, _f, next) => {
  const { customer } = await _getModules()

  await customer.waitUntilPending()

  if (customer.customerIsAdmin) {
    next()
    return
  }

  return next({ name: 'error' })
}

export { customerGuard, adminGuard }
