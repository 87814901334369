/* istanbul ignore file */
import filters from '@/services/Filters'
import TranslationsHelper from '@/services/helpers/TranslationsHelper'
import { mapState } from 'pinia'
import { useCmsVariables } from '@/stores/cmsVariables'

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: LozadHelper }, { useCmsBlocks }] = await Promise.all([
      await import('@/services/helpers/LozadHelper'),
      await import('@/stores/cmsBlocks')
    ])

    cache = { LozadHelper, cmsBlocks: useCmsBlocks(), cmsVariables: useCmsVariables() }
    return cache
  }
})()

const _fetchVariable = async (id) => {
  const { cmsVariables } = await _getModules()
  void cmsVariables.fetchOne(id)
}

export default {
  install(Vue, options) {
    Vue.prototype.$gridSizes = options.GRID_SIZES
    // Setting up mixins
    Vue.mixin({
      computed: {
        ...mapState(useCmsVariables, { $variable: 'getContent' }),
        getVariable() {
          return (id) => {
            _fetchVariable(id)
            return this.$variable(id)
          }
        }
      },
      methods: {
        async hitLozad() {
          const { LozadHelper } = await _getModules()
          await this.$nextTick()
          LozadHelper.hitLozad()
        },
        async forceLozad() {
          const { LozadHelper } = await _getModules()
          await this.$nextTick()
          await LozadHelper.forceLozad(this.$el)
        },
        translate(key, ...data) {
          return TranslationsHelper.translate(key, ...data)
        },
        async getBlock(identifier) {
          const { cmsBlocks } = await _getModules()
          return cmsBlocks.fetchOne(identifier)
        }
      }
    })

    for (const filter in filters) {
      Vue.filter(filter, filters[filter])
    }
  }
}
