class CmsBlockModel {
  constructor ({ id, is_enabled: isEnabled, value, css, js }) {
    this.id = id
    this.isEnabled = isEnabled
    this.value = value
    this.css = css
    this.js = js
  }
}

export default CmsBlockModel
