import $router from '@/router/index'

const SEPARATOR = ';'
const DEFAULT_PAGINATION_KEY = 'pagination_page'

let params = {}
class Query {
  constructor() {
    params = $router.currentRoute.query
  }

  getSearchParams() {
    return params
  }

  getSearchParam(key) {
    return params[key] ?? null
  }

  getPaginationPage(paginationKey = DEFAULT_PAGINATION_KEY) {
    return +this.getSearchParam(paginationKey) || 1
  }

  setPaginationPage(page, paginationKey = DEFAULT_PAGINATION_KEY) {
    return this.setSearchParam(paginationKey, page, false)
  }

  setSearchParam(key, value, append = false) {
    if (append && params[key]) {
      params = { ...params, [key]: `${params[key]}${SEPARATOR}${value}` }
      return this
    }
    params = { ...params, [key]: value }
    return this
  }

  clearSearchParams() {
    params = {}
    return this
  }

  removeSearchParam(key, toDelete = null) {
    const accessor = params[key] + ''
    if (!accessor) return this

    if (toDelete && accessor.split(SEPARATOR).length > 1) {
      params =  { ...params, [key]: _sanitizeParam(accessor, toDelete) }
      return this
    }

    const { [key]: _, ...rest } = params
    params = rest
    return this
  }
}

/*
* filters and combines attributes into a single string val1;val2
* ('val1;val2;val3', 'val2') => 'val1;val3'
*/
const _sanitizeParam = (param, toDelete) => {
  const check = `${toDelete}`

  const values = param.split(SEPARATOR).filter(val => val !== check)
  return values.join(SEPARATOR)
}

export default Query
