import './polyfills'
// Setup vue
import Vue, { markRaw } from 'vue'
import VueMeta from 'vue-meta'
// Global setup
import './assets/styles/bootstrap.min.css'
import './assets/styles/global.scss'
// External scripts
import './external_scripts'

import App from './App'
import { HTML_EVENT } from '@/constants/GlobalConstants'
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min'
import './services/FontLoader'
// Custom plugins
import Plugins from '@/plugins'
import filters from './services/Filters'
import vuetify from '@/plugins/vuetify'
import '@/plugins/elbwalker'
import { i18n } from '@/plugins/i18n'
import pinia from '@/plugins/pinia'
import './registerServiceWorker'
import router from '@/router'
import '@/services/SiteConfig'

const MOUNT_POINT = '#app'
const GRID_SIZES = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const _getGridBreakpoint = (screenWidth) => {
  let breakPoint = GRID_SIZES.xl
  let gridSizeKeys = Object.keys(GRID_SIZES)
  gridSizeKeys.some((gridSize, index) => {
    if (screenWidth < GRID_SIZES[gridSizeKeys[index + 1]]) {
      breakPoint = GRID_SIZES[gridSize]
      return true
    }
  })
  return breakPoint
}

const _setupSmoothScroll = () => {
  SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    header: '.navbar > div',
    easing: 'easeInOutCubic',
    updateURL: false
  })
}

const _setupVue = () => {
  Vue.config.productionTip = false
  Vue.use(VueMeta)
  Vue.use(Plugins, { filters, GRID_SIZES })
}
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

_setupVue()

new Vue({
  router,
  pinia,
  i18n,
  vuetify,
  render: h => h(App),
  data() {
    return {
      gridBreakpoint: null,
      showScoreInfo: false
    }
  },
  created() {
    this.gridBreakpoint = _getGridBreakpoint(innerWidth)
    addEventListener(HTML_EVENT.RESIZE, () => {
      this.gridBreakpoint = _getGridBreakpoint(innerWidth)
    })
    _setupSmoothScroll()
  }
}).$mount(MOUNT_POINT)
