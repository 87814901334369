const MetaProductSchema = {
  name: 'MetaProductSchema',
  fields: {
    url: null,
    product_id: null,
    campaign_id: null,
    is_enabled: null,
    attribute_set: null,
    type: null,
    name: null,
    manufacturer: null,
    tags: null,
    image_src: null,
    image_retina_src: null,
    reason_why_one: null,
    successor_url: null,
    show_catch_phrase: null,
    ratings_count: null,
    ratings_average: null,
    heart_ratings: {
      title: null,
      total_count: null,
      values: null
    },
    catch_phrase: null,
    awards: null,
    last_purchase_date: null,
    has_been_bought: null,
    rating: {
      is_ratable: null,
      text: null,
      title: null,
      value: null
    },
    milesandmore: {
      earn: null,
      spend: null
    },
    recommendation: {
      customer_salutation: null,
      is_recommendation: null,
      type: null
    },
    wishlist_item_id: false,
    price: {
      original_formatted: null,
      price_per_unit_formatted: null,
      final_formatted: null,
      final: null,
      srp: null,
      tax_rate: null,
      savings: {
        percentage: null,
        amount: null
      },
      show_countdown: null,
      valid_until: null
    },
    contents: {
      unit: null,
      amount: null
    },
    image_hero_src: null,
    image_hero_retina_src: null,
    vendor: null,
    wine_alcohol_concentration: null,
    distributor: null,
    legal: {},
    legal_information_string: null,
    contains_sulfide: null,
    delivery: {
      is_in_stock: null,
      vendor: null,
      vendor_url: null,
      time: null
    },
    qty: {
      text: {
        singular: null,
        plural: null
      },
      values: null
    },
    ga_data: {
      brand: null,
      category: null,
      id: null,
      name: null,
      price: null,
      variant: null
    },
    expert_reviews: null,
    wine_maker_products: null,
    characteristics: null,
    bundle_items: null,
    manufacturer_url: null,
    score_info: {
      score: null,
      boost: null,
      popularity: null
    }
  }
}
export default MetaProductSchema
