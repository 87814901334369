import MetaProduct from '@/modules/product/MetaProduct'

class UpsellProduct extends MetaProduct {
  constructor ({ is_selected: isSelected, ...data }) {
    super(data)
    this.is_selected = !!isSelected
  }
}

export default UpsellProduct
