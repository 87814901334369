import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import IconCheckboxOn from '@/components/basic/atoms/IconCheboxOn'
import IconCheckboxOff from '@/components/basic/atoms/IconCheboxOff'
import IconRadioButtonOn from '@/components/basic/atoms/IconRadioButtonOn'
import IconRadioButtonOff from '@/components/basic/atoms/IconRadioButtonOff'
import IconSearch from '@/components/basic/atoms/IconSearch'
import IconClose from '@/components/basic/atoms/IconClose'
import IconInfoFilled from '@/components/basic/atoms/IconInfoFilled'
import IconEyeOn from '@/components/basic/atoms/IconEyeOn'
import IconEyeOff from '@/components/basic/atoms/IconEyeOff'
import IconExpand from '@/components/basic/atoms/IconExpand'
import IconCollapse from '@/components/basic/atoms/IconCollapse'

Vue.use(Vuetify)

const opt = {
  theme: {
    themes: {
      light: {
        primary: '#222222',
        secondary: '#222222',
        accent: '#99003f',
        error: '#EE5B5B',
        success: '#4CAF50',
        warning: '#EE5B5B'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      customInfoFilled: {
        component: IconInfoFilled
      },
      customEyeOn: {
        component: IconEyeOn
      },
      customEyeOff: {
        component: IconEyeOff
      },
      customCheckboxOn: {
        component: IconCheckboxOn
      },
      customCheckboxOff: {
        component: IconCheckboxOff
      },
      customRadioButtonOn: {
        component: IconRadioButtonOn
      },
      customRadioButtonOff: {
        component: IconRadioButtonOff
      },
      customSearch: {
        component: IconSearch
      },
      customClose: {
        component: IconClose
      },
      customExpand: {
        component: IconExpand
      },
      customCollapse: {
        component: IconCollapse
      }
    }
  }
}

export default new Vuetify(opt)
